<template>
    <section class="anuncios-crear mt-4 ml-4" v-loading="loading">
        <ValidationObserver tag="div">
            <div class="row">
                <div class="col-12">
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                        <el-breadcrumb-item :to="{ name: 'anuncios.main' }">Mis anuncios</el-breadcrumb-item>
                        <el-breadcrumb-item>Nuevo anuncio</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <p class="f-20 f-500 col-12 mb-3">Nuevo anuncio</p>
                         <ValidationProvider tag="div" class="col-12 col-sm-6 mb-3">
                            <p class="f-12 pl-2 label-inputs">Título</p>
                            <el-input placeholder="Título del anuncio" v-model="cartelera.titulo" size="small" class="w-100"></el-input>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <div class="d-flex">
                                <div class="wh-32px rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                                    <i class="icon-account-outline op-05 f-20" />
                                </div>
                                <div class="ml-2 flex-fill">
                                    <p class="f-12 pl-2 label-inputs">Tipo anuncio</p>
                                    <ValidationProvider tag="div">
                                        <el-select v-model="cartelera.id_categoria" placeholder="Seleccionar tipo" size="small" class="w-100">
                                            <el-option v-for="(categoria,i) in categorias" :key="i" :label="categoria.nombre" :value="categoria.id"> </el-option>
                                        </el-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <ValidationProvider tag="div">
                                <p class="f-12 pl-2 label-inputs">Valor</p>
                                <el-input type="number" placeholder="Escribe el valor del elemento" v-model="cartelera.dinero" size="small"></el-input>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider tag="div">
                                        <p class="f-12 pl-2 label-inputs">Fecha de inicio</p>
                                        <el-date-picker v-model="cartelera.vigencia_inicio" type="date" placeholder="Seleccionar la fecha" size="small" class="w-100" />
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 mt-3">
                                    <ValidationProvider tag="div">
                                        <p class="f-12 pl-2 label-inputs">Duración (días)</p>
                                        <el-input placeholder="Número de días" v-model="duracion" size="small"></el-input>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 mb-3">
                            <ValidationProvider tag="div">
                                <p class="f-12 pl-2 label-inputs">Descripción</p>
                                <el-input placeholder="Descripción del anuncio" v-model="cartelera.descripcion" type="textarea"  :autosize="{ minRows: 4, maxRows: 4}" />
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-auto d-flex">
                            <i :class="`icon-pin mr-2 cr-pointer f-17 ${cartelera.top == 1 ? 'text-general' : ''}`" @click="cartelera.top = (cartelera.top == 1) ? 0 : 1" />
                            <p class="f-14">Fijar el anuncio</p>
                        </div>
                        <div class="col-auto d-flex el-checkbox-negro">
                            <el-checkbox v-if="this.cartelera.destacado == 1" :value="true" @change="toggleDestacado">Destacar el anuncio</el-checkbox>
                            <el-checkbox v-else @change="toggleDestacado">Destacar el anuncio</el-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-auto">
                            <button class="btn btn-general h-32px f-14 f-300 px-4" @click="guardar">Publicar</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row mb-3">
                        <div v-if="cartelera.id" class="col-auto">
                            <p class="f-20 f-500 col-12">Imagenes</p>
                        </div>
                        <div v-if="cartelera.id" class="col-auto ml-auto">
                            <button class="h-32px px-4 border br-4 f-300 text-86 bg-f5 f-14">Añadir imagen</button>
                        </div>
                    </div>
                    <div v-if="cartelera.id" class="row">
                      <el-upload
                        action="#"
                        list-type="picture-card"
                        :file-list="imagenes"
                        :on-change="handleChange"
                        :multiple="false"
                        :auto-upload="false">
                          <i slot="default" class="el-icon-plus"></i>
                          <div slot="file" slot-scope="{file}">
                            <img
                              class="el-upload-list__item-thumbnail"
                              :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                              <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                              >
                                <i class="el-icon-zoom-in"></i>
                              </span>
                              <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                              >
                                <i class="el-icon-download"></i>
                              </span>
                              <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="eliminarImagen(file)"
                              >
                                <i class="el-icon-delete"></i>
                              </span>
                            </span>
                          </div>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                      </el-dialog>  
                      
                  </div>
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
        <modal-ver-img ref="modalabrirVerImg" />
    </section>
</template>
<script>
import Carteleras from '~/services/carteleras'
import Categorias from '~/services/carteleras/categorias'
import moment from 'moment'

export default {
    components:{
        modalVerImg: () => import('./partials/modalVerImg'),
    },
    data(){
        return{

            categorias:[],
            categoria:{
              
            },

            loading: false,
            duracion : 0,
            cartelera:{
              id: null,
              id_categoria : 2,
              titulo: '',
              dinero: 0,
              descripcion: '',
              vigencia_inicio: null,
              vigencia_fin: null,
              imagenes: [],
              top: 0,
              destacado: 0
            },
            imagenes:[],

            dialogImageUrl: '',
            dialogVisible: false,

            // before ->
            
            
        }
    },
    created(){
      this.listarCategorias()
      if(typeof this.$route.params.id_cartelera != 'undefined'){
        this.mostrar()
      }
    },
    watch:{
      duracion(val){
        let inicio = _.get(this.cartelera, 'vigencia_inicio')
        console.log(inicio)
        if(inicio){
          this.cartelera.vigencia_fin = moment(inicio).add(val, 'days')
          this.cartelera.vigencia_inicio = moment(inicio).format('YYYY-MM-DD')
          this.cartelera.vigencia_fin = moment(this.cartelera.vigencia_fin).format('YYYY-MM-DD')
        }
      }
    },
    cartelera: {
      handler(val){
          // console.log(moment(val.vigencia_fin).format('YYYY-MM-DD'))
      },
      deep: true
    },
    methods:{
        
        eliminarImagen(){

        },
        verImagen(){
            this.$refs.modalabrirVerImg.toggle()
        },
        setComoPortada(){

        },
        toggleDestacado(){
          this.cartelera.destacado = 1 - this.cartelera.destacado
        },
        handleChange(file, ){          
          this.subirImagen(file)
        },
        async listarCategorias(){
          try {
            this.loading = true
            let params={
              caso: 1
            }
            const {data} = await Categorias.listar(params)
            this.categorias = data.data
            if(! this.cartelera.id){
              this.cartelera.vigencia_inicio = moment().format('YYYY-MM-DD')
              this.duracion = 1
            }
            
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }          
        },
        async subirImagen(file){
          try {
            this.loading = true
            let formData = new FormData();
            formData.append('imagen', file.raw);
            formData.append('id_cartelera', this.cartelera.id);
            const {data} = await Carteleras.subirImagen(formData)
            this.loading = false
            if(data.success){
              this.listarImagenes()
            }
          } catch (e){
            this.loading = false
            console.log(e)
          }
        },
        async listarImagenes(){
          try {
            this.loading = true
            const {data} = await Carteleras.listarImagenes(this.$route.params.id_cartelera)
            this.loading = false
            if(data.success){
              this.zona.imagenes = data.data
              this.zona.imagenes.forEach(imagen => {
                imagen.url = imagen.imagen
              });
              this.imagenes = this.zona.imagenes
            }
          } catch (e){
            this.loading = false
            console.log(e)
          }
        },
        async guardar(){
          try {
            this.loading = true
            let payload= {
              id_cartelera : this.cartelera.id,
              titulo : this.cartelera.titulo,
              descripcion : this.cartelera.descripcion,
              mostrar_datos: 1,
              vigencia_inicio: this.cartelera.vigencia_inicio,
              vigencia_fin: this.cartelera.vigencia_fin,
              dinero: this.cartelera.dinero,
              id_categoria: this.cartelera.id_categoria,
              top: this.cartelera.top,
              destacado: this.cartelera.destacado
            }
            const {data} = await Carteleras.guardar(payload)
            this.loading = false
            if(data.success){
              // this.cartelera.imagenes = data.data              
              // this.zona.imagenes.forEach(imagen => {
              //   imagen.url = imagen.imagen
              // });
              // this.imagenes = this.cartelera.imagenes
              this.$notify({
                title: 'Crear Anuncio',
                message: data.mensaje,
                type: 'success'
              });
              this.mostrar()
            }
          } catch (e){
            this.loading = false
            this.$notify({
              title: 'Crear Cartelera',
              message: 'Error guardando',
              type: 'warning'
            });
            console.log(e)
          }
        },
        async mostrar(){
          try {
            this.loading = true
            
            const {data} = await Carteleras.mostrar(this.$route.params.id_cartelera)
            this.cartelera = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
        
        
    }
}
</script>
<style lang="scss" scoped>
.anuncios-crear{
    .container-img{
        width: 120px;
        height: 120px;
        position: relative;
        .portada{
            background: #93EDCF;
            width: 54px;
            height: 20px;
            position: absolute;
            top: -10px;
            left: 33px;
            border: 1px solid white;
        }
        i.icon-me_interesa{
            position: absolute;
            right: 3px;
            top: 3px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
}
</style>